<template>
  <div class="upload-excel">
    <div class="container">
      <div class="file-wrapper" :class="{ 'succes-file-excel': value != null }">
        <input
          type="file"
          name="file-input"
          id="uploadInput"
          @change="handleFileInput"
        />
        <div class="upload_label">
          <font-awesome-icon icon="cloud-upload-alt" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
export default {
  props: { value: { required: true } },
  data() {
    return { file: null };
  },
  methods: {
    ...mapActions(['uploadFileExcelNumDossier']),
    async handleFileInput(e) {
      let files = e.target.files;
      files = e.target.files;
      if (!files) return;
      [...files].forEach(f => {
        this.file = f;
      });
      document.getElementById('uploadInput').value = '';
      const response = await this.uploadFileExcelNumDossier(this.file);
      if (response.succes) {
        this.$emit('searchWithNumDossier', response.data);
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.upload-excel {
  .container {
    height: 30px;
    width: 59px;
    .file-wrapper {
      text-align: center;
      width: 100%;
      height: 100%;
      vertical-align: middle;
      position: relative;
      overflow: hidden;
      border: 1px solid #4d4bac;
      background-color: #4d4bac;
      color: #fff;
      border-radius: 16px;
      box-shadow: 1px 2px 5px 5px #f5f5f5;
      padding: 3px;
      input {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        height: 100%;
        width: 100%;
        opacity: 0;
        cursor: pointer;
        z-index: 5;
      }
      .display {
        display: none;
      }
      .upload_label {
        font-size: 16px;
        position: relative;
      }
    }
    .succes-file-excel {
      border: 1px solid #1b7811;
      background-color: #1b7811;
    }
  }
}
</style>
